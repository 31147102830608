import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export const CONTACTS_DATA = [
  {
    icon: faPhone,
    target: undefined,
    text: '212.300.9890',
    link: 'tel:212.300.9890',
  },
  {
    icon: faEnvelope,
    target: undefined,
    text: 'Information',
    link: 'mailto:info@luxusexperiences.co',
  },
];

export const SOCIAL_LINKS = [
  {
    icon: faLinkedinIn,
    target: '_blank',
    text: 'LinkedIn',
    link: 'https://www.linkedin.com/company/luxusassets/',
  },
  {
    icon: faInstagram,
    target: '_blank',
    text: 'Instagram',
    link: 'https://www.instagram.com/luxusassets/',
  },
];
