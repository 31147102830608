import React, { ChangeEvent, useRef, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, SubmitHandler, Controller } from 'react-hook-form';
import styles from './JoinForm.module.scss';
import { getIPFromAmazon } from 'api/common';
import { getHubSpotCookie, sendHubSpotData } from 'api/hubspot';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import InputFieldV2, {
  InputFieldTheme,
} from 'components/UIComponents/Input/InputField/InputFieldV2';
import InputSubmitV2, {
  InputSubmitTheme,
} from 'components/UIComponents/Input/InputSubmit/InputSubmitV2';
import RadioButton from 'components/UIComponents/Radio/RadioButton';

interface IJoinFormInput {
  email: string;
  agreement: boolean;
}

export enum HubspotForms {
  GET_NOTIFIED = 'get_notified',
  ROYAL_ASCOT = 'royal_ascot',
  ABU_DHABI = 'abu_dhabi',
  AUSTIN = 'austin',
  PALM_BEACH = 'palm_beach',
  BREEDERS_CUP = 'breeders_cup',
}

export const getFormTitleByEventName = (formName: HubspotForms) => {
  switch (formName) {
    case HubspotForms.ABU_DHABI:
      return 'at the 2024 Abu Dhabi Art Fair';
    case HubspotForms.AUSTIN:
      return 'at the 2024 Southwest Luxury Auto-motives Dinner';
    case HubspotForms.PALM_BEACH:
      return 'at the 2025 Palm Beach Yacht Week';
    case HubspotForms.BREEDERS_CUP:
      return 'at the 2024 Breeders Cup';
    case HubspotForms.GET_NOTIFIED:
      return 'Get notified about upcoming events';
    default:
      return '';
  }
};

const getFormId = (formName: HubspotForms) => {
  switch (formName) {
    case HubspotForms.ROYAL_ASCOT:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_ROYAL_ASCOT_FORM_ID;
    case HubspotForms.ABU_DHABI:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_ABU_DHABI_FORM_ID;
    case HubspotForms.AUSTIN:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_AUSTIN_FORM_ID;
    case HubspotForms.PALM_BEACH:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_PALM_BEACH_FORM_ID;
    case HubspotForms.BREEDERS_CUP:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_BREEDERS_CUP_FORM_ID;
    case HubspotForms.GET_NOTIFIED:
      return process.env.REACT_APP_HUBSPOT_EXPERIENCES_GET_NOTIFIED_FORM_ID;
    default:
      return '';
  }
};

type JoinFormProps = {
  pageName: string;
  pageUri: string;
  buttonText: string;
  formName: HubspotForms;
};

const defaultValues: IJoinFormInput = {
  email: '',
  agreement: false,
};

const validationSchema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email address.')
      .required('Email is required.'),
    agreement: yup
      .boolean()
      .oneOf([true], 'You must agree to the terms.')
      .required('You must agree to the terms.'),
  })
  .required();

const JoinForm: React.FC<JoinFormProps> = ({
  buttonText,
  pageName,
  pageUri,
  formName,
}) => {
  const { control, handleSubmit, setValue, formState } =
    useForm<IJoinFormInput>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onChange',
    });

  const agreement = useWatch({ control, name: 'agreement' });

  const onRadioChange = () => {
    setValue('agreement', !agreement, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<IJoinFormInput> = async (data) => {
    try {
      const ip = await getIPFromAmazon();

      const formId = getFormId(formName) || '';
      const trackingCookie = getHubSpotCookie() || '';

      const response = await sendHubSpotData(
        {
          email: data.email,
          ipAddress: ip,
          trackingCookie,
          pageUri,
          pageName,
        },
        formId
      );

      if (response.inlineMessage === 'Thanks for submitting the form.') {
        return Promise.resolve();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form>
        <div className={styles.formContainer}>
          {!formState.isSubmitSuccessful && (
            <>
              <div className={styles.content}>
                <h2 className={styles.titleSecond}>
                  GET ON THE LIST to join us {getFormTitleByEventName(formName)}
                </h2>
              </div>
              <div className={styles.inputGroupContainer}>
                <div className={styles.inputGroupRow}>
                  <InputSubmitV2<IJoinFormInput>
                    theme={InputSubmitTheme.WHITE}
                    placeholder={'EMAIL'}
                    name="email"
                    control={control}
                    buttonText={buttonText}
                    onSubmitClick={handleSubmit(onSubmit)}
                  />
                </div>
                <div
                  className={styles.inputGroupRow}
                  style={{ alignItems: 'center' }}
                >
                  <Controller
                    name={'agreement'}
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <RadioButton.White
                        label=""
                        value={value}
                        onChange={() => {}}
                        onClick={onRadioChange}
                        error={!!error}
                      />
                    )}
                  />
                  <p className={styles.agreementNotice}>
                    I have read and agree to the{' '}
                    <Link
                      target="_blank"
                      style={{ color: 'inherit' }}
                      to={ROUTES.PRIVACY_POLICY}
                    >
                      Privacy Policy
                    </Link>
                    ,{'  '}
                    <Link
                      target="_blank"
                      style={{ color: 'inherit' }}
                      to={ROUTES.TERMS_OF_SERVICE}
                    >
                      Terms of Service
                    </Link>
                  </p>
                </div>
              </div>
            </>
          )}
          {formState.isSubmitSuccessful && (
            <div className={styles.successContainer}>
              <h2 className={styles.formTitleSuccess}>
                You’re On the Waitlist
              </h2>
              <p className={styles.formTextSuccess}>
                We'll be in touch with you shortly.
              </p>
            </div>
          )}
        </div>
        {/* <DevTool control={control} /> */}
      </form>
    </>
  );
};

export default JoinForm;
