'use client';

import cs from 'classnames';
import { InputFieldProps } from './InputField.type';
import styles from './InputFieldV2.module.scss';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormState,
} from 'react-hook-form';

type InputSubmitProps<T extends FieldValues> = InputFieldProps & {
  control: Control<T>;
  theme: InputFieldTheme;
  fullWidth?: boolean;
};

export enum InputFieldTheme {
  WHITE = 'white',
  BLACK = 'black',
}

const InputFieldV2 = <T extends FieldValues>(props: InputSubmitProps<T>) => {
  const { errors } = useFormState<T>({ control: props.control });
  return (
    <div
      className={cs(styles.inputContainer, {
        [styles.fullWidth]: props.fullWidth,
      })}
    >
      <Controller
        name={props.name as Path<T>}
        control={props.control}
        render={({
          field: { onChange, disabled, value },
          fieldState: { error },
        }) => (
          <input
            className={cs({
              [styles.inputFieldBlack]: props.theme === InputFieldTheme.BLACK,
              [styles.inputFieldWhite]: props.theme === InputFieldTheme.WHITE,
              [styles.error]: error,
            })}
            disabled={disabled}
            placeholder={props.placeholder}
            value={value}
            onChange={onChange}
            name={props.name}
          />
        )}
      />
      {errors[props.name] && (
        <span className={styles.errorMessage}>
          {String(errors[props.name]?.message)}
        </span>
      )}
    </div>
  );
};

export default InputFieldV2;
