import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FooterLinks from './FooterLinks/FooterLinks';
import LuxusExperienceLogo from 'assets/icons/LuxusExperienceLogo';

import styles from './FooterNew.module.scss';
import { CONTACTS_DATA } from './constants';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import LuxusExperienceLogoTextWhite from 'assets/icons/LuxusExperienceLogoTextWhite';
import FooterFormV2 from './FooterForm/FooterFormV2';

type FooterNewProps = {
  pageName: string;
  pageUri: string;
};

const FooterNew = ({ pageUri, pageName }: FooterNewProps) => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footer}>
        <FooterFormV2
          pageName={pageName}
          pageUri={pageUri}
          version="secondary"
        />
        <div className={styles.data__container}>
          <div className={styles.data__item}>
            <div className={styles.contactIconContainer}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <a
              className={styles.contactLink}
              href="https://www.google.com/maps/place/1270+6th+Ave+7th+1071,+New+York,+NY+10020/@40.7600704,-73.9820039,17z/data=!3m1!4b1!4m5!3m4!1s0x89c258f928e3f70d:0x39744640afd26d8e!8m2!3d40.7600664!4d-73.9798152"
              target="_blank nofollow noreferrer"
            >
              1270 Ave of the Americas,
              <br /> 7th Fl – 1071 New York,
              <br /> NY 10020
            </a>
          </div>
          <div>
            <ul className={styles.contactList}>
              {CONTACTS_DATA.map((contact, index) => (
                <li className={styles.contactItem} key={index}>
                  <div className={styles.contactIconContainer}>
                    <FontAwesomeIcon
                      className={styles.contactIcon}
                      icon={contact.icon}
                    />
                  </div>
                  <a
                    className={styles.contactLink}
                    href={contact.link}
                    target={contact.target}
                  >
                    {contact.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={styles.logoContainer}
          onClick={() => window.scroll(0, 0)}
        >
          <LuxusExperienceLogoTextWhite />
        </div>
        <FooterLinks />
      </div>
    </footer>
  );
};

export default FooterNew;
