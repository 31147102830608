const CoinsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M19.6875 2.7793C16.8398 2.7793 14.2617 3.30664 12.3926 4.15039C12.3398 4.17383 9.375 5.38086 9.375 7.4668V8.44531C4.45898 8.65039 0 10.4785 0 13.0918V24.3418C0 26.9316 4.61719 29.0293 10.3125 29.0293C16.0078 29.0293 20.625 26.9316 20.625 24.3418V23.3809C25.8809 23.1641 30 21.1602 30 18.7168V7.4668C30 5.29883 26.3613 2.7793 19.6875 2.7793ZM18.75 24.3418C18.75 25.1562 15.791 27.1543 10.3125 27.1543C4.83398 27.1543 1.875 25.1562 1.875 24.3418V21.4062C3.73828 22.6133 6.82031 23.4043 10.3125 23.4043C13.8047 23.4043 16.8867 22.6133 18.75 21.4062V24.3418ZM18.75 18.7168C18.75 19.5312 15.791 21.5293 10.3125 21.5293C4.83398 21.5293 1.875 19.5312 1.875 18.7168V15.7812C3.73828 16.9883 6.82031 17.7793 10.3125 17.7793C13.8047 17.7793 16.8867 16.9883 18.75 15.7812V18.7168ZM10.3125 15.9043C5.56641 15.9043 1.7168 14.6445 1.7168 13.0918C1.7168 11.5391 5.56641 10.2793 10.3125 10.2793C15.0586 10.2793 18.9082 11.5391 18.9082 13.0918C18.9082 14.6445 15.0586 15.9043 10.3125 15.9043ZM28.125 18.7168C28.125 19.4844 25.4883 21.2715 20.625 21.4883V17.7324C23.7188 17.6035 26.4316 16.877 28.125 15.7812V18.7168ZM28.125 13.0918C28.125 13.8594 25.4824 15.6348 20.625 15.8574V13.0918C20.625 12.7637 20.5254 12.4473 20.3848 12.1367C23.584 12.0371 26.3848 11.2871 28.125 10.1562V13.0918ZM19.6875 10.2793C19.2598 10.2793 18.8672 10.25 18.4629 10.2266C16.3008 8.95508 13.4824 8.57422 11.6895 8.46875C11.3203 8.1582 11.0977 7.82422 11.0977 7.4668C11.0977 5.91406 14.9473 4.6543 19.6934 4.6543C24.4395 4.6543 28.2891 5.91406 28.2891 7.4668C28.2891 9.01953 24.4336 10.2793 19.6875 10.2793Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default CoinsIcon;
