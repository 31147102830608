import styles from './HeroSection.module.scss';

const HeroSection = () => {
  return (
    <div className={styles.heroContainer}>
      <h1 className={styles.title}>
        Exposure. <span className={styles.till}>Experiences.</span> Education.
      </h1>
      <p className={styles.description}>
        Luxury assets such as jewels, thoroughbreds, vintage cars, wines +
        spirits can be enjoyed in a way that most investment grade assets
        cannot. LUXUS combines investment opportunities with experiences around
        the world to both (1) enhance and amplify the value of each asset in the
        LUXUS portfolio and (2) to provide LUXUS investors with the best luxury
        experiences around the world.
      </p>
    </div>
  );
};

export default HeroSection;
