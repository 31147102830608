export const settings = {
  dots: false,
  infinite: true,
  speed: 650,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const sliderDataOrdered = [
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/1.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/2.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/3.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/4.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/5.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/6.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/7.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/8.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/9.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/10.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/11.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/12.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/13.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/14.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/15.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/16.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/17.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/17.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/18.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/19.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/20.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/21.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/21.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/22.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/23.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/24.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/25.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/26.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/27.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/28.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/29.jpg',
  'https://d1p3kxhg87s54m.cloudfront.net/assets/images/vip_carousel/30.jpg',
];

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const sliderData = shuffleArray(sliderDataOrdered);
