export const ArrowBottomIcon = ({
  color,
  width = '8',
  height = '6',
}: {
  color: string
  width?: string
  height?: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 8 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      width={width}
      height={height}
      d='M3.59656 4.90222C3.8197 5.12536 4.18208 5.12536 4.40522 4.90222L7.83264 1.47479C8.05579 1.25165 8.05579 0.889274 7.83264 0.666134C7.60951 0.442994 7.24713 0.442994 7.02399 0.666134L4 3.69012L0.976012 0.667919C0.752873 0.444779 0.390494 0.444779 0.167355 0.667919C-0.0557849 0.891059 -0.0557849 1.25344 0.167355 1.47658L3.59478 4.904L3.59656 4.90222Z'
      fill={color}
    />
  </svg>
)
