export enum FORM_INITIAL_VALUE {
  firstName = '',
  lastName = '',
  email = '',
}

export const stateCodes = [
  { label: 'Alabama', id: 'AL' },
  { label: 'Alaska', id: 'AK' },
  { label: 'Arizona', id: 'AZ' },
  { label: 'Arkansas', id: 'AR' },
  { label: 'California', id: 'CA' },
  { label: 'Colorado', id: 'CO' },
  { label: 'Connecticut', id: 'CT' },
  { label: 'Delaware', id: 'DE' },
  { label: 'Florida', id: 'FL' },
  { label: 'Georgia', id: 'GA' },
  { label: 'Hawaii', id: 'HI' },
  { label: 'Idaho', id: 'ID' },
  { label: 'Illinois', id: 'IL' },
  { label: 'Indiana', id: 'IN' },
  { label: 'Iowa', id: 'IA' },
  { label: 'Kansas', id: 'KS' },
  { label: 'Kentucky', id: 'KY' },
  { label: 'Louisiana', id: 'LA' },
  { label: 'Maine', id: 'ME' },
  { label: 'Maryland', id: 'MD' },
  { label: 'Massachusetts', id: 'MA' },
  { label: 'Michigan', id: 'MI' },
  { label: 'Minnesota', id: 'MN' },
  { label: 'Mississippi', id: 'MS' },
  { label: 'Missouri', id: 'MO' },
  { label: 'Montana', id: 'MT' },
  { label: 'Nebraska', id: 'NE' },
  { label: 'Nevada', id: 'NV' },
  { label: 'New Hampshire', id: 'NH' },
  { label: 'New Jersey', id: 'NJ' },
  { label: 'New Mexico', id: 'NM' },
  { label: 'New York', id: 'NY' },
  { label: 'North Carolina', id: 'NC' },
  { label: 'North Dakota', id: 'ND' },
  { label: 'Ohio', id: 'OH' },
  { label: 'Oklahoma', id: 'OK' },
  { label: 'Oregon', id: 'OR' },
  { label: 'Pennsylvania', id: 'PA' },
  { label: 'Rhode Island', id: 'RI' },
  { label: 'South Carolina', id: 'SC' },
  { label: 'South Dakota', id: 'SD' },
  { label: 'Tennessee', id: 'TN' },
  { label: 'Texas', id: 'TX' },
  { label: 'Utah', id: 'UT' },
  { label: 'Vermont', id: 'VT' },
  { label: 'Virginia', id: 'VA' },
  { label: 'Washington', id: 'WA' },
  { label: 'West Virginia', id: 'WV' },
  { label: 'Wisconsin', id: 'WI' },
  { label: 'Wyoming', id: 'WY' },
];

export const stateOptions = stateCodes.map(({ label, id }) => ({
  label,
  value: id,
}));

export const nationalityOptions = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'Non US',
    value: 'Non-US',
  },
];

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  "Eswatini (fmr. 'Swaziland)",
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
].map((item) => ({ label: item, value: item }));
