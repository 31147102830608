import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cs from 'classnames';
import * as yup from 'yup';
import styles from './FooterFormV2.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import { getHubSpotCookie, sendHubSpotData } from 'api/hubspot';
import { getIPFromAmazon } from 'api/common';
import InputFieldV2, {
  InputFieldTheme,
} from 'components/UIComponents/Input/InputField/InputFieldV2';
import InputSubmitV2, {
  InputSubmitTheme,
} from 'components/UIComponents/Input/InputSubmit/InputSubmitV2';
import RadioButton from 'components/UIComponents/Radio/RadioButton';

interface IFooterFormInput {
  firstName: string;
  lastName: string;
  email: string;
  agreement: boolean;
}

type FooterFormV2Props = {
  pageName: string;
  pageUri: string;
  version: 'primary' | 'secondary';
};

const defaultValues: IFooterFormInput = {
  firstName: '',
  lastName: '',
  email: '',
  agreement: false,
};

const validationSchema = yup
  .object({
    firstName: yup.string().required('First Name is required.'),
    lastName: yup.string().required('Last Name is required.'),
    email: yup
      .string()
      .email('Invalid email address.')
      .required('Email is required.'),
    agreement: yup
      .boolean()
      .oneOf([true], 'You must agree to the terms.')
      .required('You must agree to the terms.'),
  })
  .required();

const FooterFormV2 = ({ pageName, pageUri, version }: FooterFormV2Props) => {
  const { control, handleSubmit, setValue, formState } =
    useForm<IFooterFormInput>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onChange',
    });

  const agreement = useWatch({ control, name: 'agreement' });

  const onRadioChange = () => {
    setValue('agreement', !agreement, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<IFooterFormInput> = async (data) => {
    try {
      const ip = await getIPFromAmazon();

      const formId =
        process.env.REACT_APP_HUBSPOT_FORM_EXPERIENCES_NEWSLETTER_GUID || '';
      const trackingCookie = getHubSpotCookie() || '';

      const response = await sendHubSpotData(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          ipAddress: ip,
          trackingCookie,
          pageUri,
          pageName,
        },
        formId
      );

      if (response.inlineMessage === 'Thanks for submitting the form.') {
        return Promise.resolve();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form>
      <div
        className={cs({
          [styles.primaryFormContainer]: version === 'primary',
          [styles.secondaryFormContainer]: version === 'secondary',
        })}
      >
        {!formState.isSubmitSuccessful && (
          <>
            <h2 className={styles.formTitle}>
              Subscribe to our newsletter
              <br /> for insights, updates and more
            </h2>
            <div className={styles.inputGroupContainer}>
              <div className={styles.inputGroupRow}>
                <InputFieldV2<IFooterFormInput>
                  theme={InputFieldTheme.WHITE}
                  placeholder={'FIRST NAME'}
                  name="firstName"
                  control={control}
                />
                <InputFieldV2<IFooterFormInput>
                  theme={InputFieldTheme.WHITE}
                  placeholder={'LAST NAME'}
                  name="lastName"
                  control={control}
                />
              </div>
              <div className={styles.inputGroupRow}>
                <InputSubmitV2<IFooterFormInput>
                  theme={InputSubmitTheme.WHITE}
                  placeholder={'EMAIL'}
                  name="email"
                  control={control}
                  buttonText="SUBSCRIBE"
                  onSubmitClick={handleSubmit(onSubmit)}
                />
              </div>
              <div className={cs(styles.inputGroupRow)}>
                <div className={styles.agreementNoticeContainer}>
                  <Controller
                    name={'agreement'}
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <RadioButton.White
                        label=""
                        value={value}
                        onChange={() => {}}
                        onClick={onRadioChange}
                        error={!!error}
                      />
                    )}
                  />
                  <p className={styles.agreementNotice}>
                    I have read and agree to the{' '}
                    <Link
                      target="_blank"
                      style={{ color: 'inherit' }}
                      to={ROUTES.PRIVACY_POLICY}
                    >
                      Privacy Policy
                    </Link>
                    ,{'  '}
                    <Link
                      target="_blank"
                      style={{ color: 'inherit' }}
                      to={ROUTES.TERMS_OF_SERVICE}
                    >
                      Terms of Service
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {formState.isSubmitSuccessful && (
          <div className={styles.successContainer}>
            <h2 className={styles.formTitleSuccess}>Welcome to LUXUS!</h2>
            <h3 className={styles.formSubtitleSuccess}>
              Exposure. Education. Experiences.
            </h3>
            <p className={styles.formTextSuccess}>
              You will start receiving our newsletter.
            </p>
          </div>
        )}
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
};

export default FooterFormV2;
