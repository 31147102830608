import styled from "@emotion/styled";
import { ContainerProps } from "./DropDown.type";

export const Container = styled.div<ContainerProps>`
  border: 1px #7b7b8f solid;
  border-bottom: ${(props) => (props.isOpen ? "none" : "1px #7b7b8f solid")};
  border-radius: ${(props) => (props.isOpen ? "15px 15px 0px 0px" : "30px")};
  transition: border-radius 0.5s ease;
  padding: 9px 20px;
  width: -moz-available;
  position: relative;
  font-size: 14px;
`;

export const ListContainer = styled.ul<ContainerProps>`
  position: absolute;
  width: 100%;
  top: 100%;
  left: -1px;
  right: 0;
  z-index: 1000;
  padding: 0;
  margin: 0;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
  background-color: white;
  border: 1px #7b7b8f solid;
  border-top: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: ${(props) => (props.isOpen ? "30vh" : "0px")};
  transition: max-height 0.5s ease;
  border-radius: 0px 0px 15px 15px;
  overflow: auto;
`;
