import { Link } from "react-router-dom";
import styles from "./FooterLinks.module.scss";
import { ROUTES } from "utils/constants";

const FooterLinks = () => {
  return (
    <div className={styles.linksContainer}>
      <p className={styles.text}>
        COPYRIGHT ©{new Date().getFullYear()} ALL RIGHTS RESERVED |
      </p>
      <Link
        className={styles.link}
        target="_blank"
        to={ROUTES.TERMS_OF_SERVICE}
      >
        TERMS OF SERVICE
      </Link>
      <Link className={styles.link} target="_blank" to={ROUTES.PRIVACY_POLICY}>
        {" "}
        | PRIVACY POLICY
      </Link>
    </div>
  );
};

export default FooterLinks;
