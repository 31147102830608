import { forwardRef } from 'react';
import { Controller, Control } from 'react-hook-form';

import { PhoneDropDownComponent } from 'components/UIComponents/PhoneDropDown/PhoneDropDown';

import styles from './ControlledPhoneDropDown.module.scss';

type ControlledDropdownProps = {
  name: string;
  placeholder: string;
  control: Control<any>;
  isError: boolean;
  errorMessage?: string;
  placeholderColor?: string;
};

const ControlledPhoneDropDown = forwardRef<
  HTMLDivElement,
  ControlledDropdownProps
>(
  (
    { name, isError, errorMessage, placeholder, control, placeholderColor },
    ref
  ) => {
    return (
      <div style={{ width: '100%' }}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <PhoneDropDownComponent.White
                buttonType="button"
                placeholderColor={placeholderColor}
                value={value}
                onChange={(value) => onChange(value)}
                placeholder={placeholder}
                borderColor={isError ? '#FF0000' : '#fff'}
                color="#fff"
              />
            );
          }}
        />
        {isError && <span className={styles.errorMessage}>{errorMessage}</span>}
      </div>
    );
  }
);

ControlledPhoneDropDown.displayName = 'ControlledPhoneDropDown';

export default ControlledPhoneDropDown;
