import { ReactNode } from 'react';

import ChartIcon from 'assets/icons/UpcomingEventsIcons/Chart';
import CoinsIcon from 'assets/icons/UpcomingEventsIcons/Coins';
import DiamondIcon from 'assets/icons/UpcomingEventsIcons/Diamond';
import GlobeIcon from 'assets/icons/UpcomingEventsIcons/Globe';

interface ICard {
  id: number;
  title: string;
  placeAndDate: string;
  description: string;
  imageUrl: string;
  icon: ReactNode;
  backgroundImageUrl: string;
}

export const cards: ICard[] = [
  {
    id: 1,
    title: 'Southwest Luxury Auto-motives Dinner',
    placeAndDate: 'Austin, Texas | October 22nd, 2024',
    description:
      'Join us for an evening of meaningful connections with UHNW and Family Offices from the South and Southwest in the heart of Austin, Texas.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_main.jpg',
    icon: <CoinsIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_bg.jpg',
  },
  {
    id: 2,
    title: 'Breeders Cup',
    placeAndDate: 'Delmar, California | November 1st-2nd, 2024',
    description:
      "The 2024 Breeders' Cup World Championships, consisting of 14 Grade 1 Championship races and $31 million in purses and awards.",
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_main.jpg',
    icon: <DiamondIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_bg.jpg',
  },
  {
    id: 3,
    title: 'Abu Dhabi Art Fair',
    placeAndDate: 'Abu Dhabi, UAE | November 20-24th, 2024',
    description:
      'The fair showcases global art, featuring galleries, exhibitions, and installations across the emirate.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_main.jpg',
    icon: <GlobeIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_bg.jpg',
  },
  {
    id: 4,
    title: 'Palm Beach Yacht Week',
    placeAndDate: 'Palm Beach, Florida | March 20-23, 2025',
    description:
      'Throughout the 4-day spectacle, guests will be treated to the latest in yacht and superyacht designs, sportfishing boats, cruisers.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_main.jpg',
    icon: <ChartIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_bg.jpg',
  },
  {
    id: 5,
    title: 'Southwest Luxury Auto-motives Dinner',
    placeAndDate: 'Austin, Texas | October 22nd, 2024',
    description:
      'Join us for an evening of meaningful connections with UHNW and Family Offices from the South and Southwest in the heart of Austin, Texas.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_main.jpg',
    icon: <CoinsIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_bg.jpg',
  },
  {
    id: 6,
    title: 'Breeders Cup',
    placeAndDate: 'Delmar, California | November 1st-2nd, 2024',
    description:
      "The 2024 Breeders' Cup World Championships, consisting of 14 Grade 1 Championship races and $31 million in purses and awards.",
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_main.jpg',
    icon: <DiamondIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_bg.jpg',
  },
  {
    id: 7,
    title: 'Abu Dhabi Art Fair',
    placeAndDate: 'Abu Dhabi, UAE | November 20-24th, 2024',
    description:
      'The fair showcases global art, featuring galleries, exhibitions, and installations across the emirate.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_main.jpg',
    icon: <GlobeIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_bg.jpg',
  },
  {
    id: 8,
    title: 'Palm Beach Yacht Week',
    placeAndDate: 'Palm Beach, Florida | March 20-23, 2025',
    description:
      'Throughout the 4-day spectacle, guests will be treated to the latest in yacht and superyacht designs, sportfishing boats, cruisers.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_main.jpg',
    icon: <ChartIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_bg.jpg',
  },
];

export const cardsMobile: ICard[] = [
  {
    id: 1,
    title: 'Southwest Luxury Auto-motives Dinner',
    placeAndDate: 'Austin, Texas | October 22nd, 2024',
    description:
      'Join us for an evening of meaningful connections with UHNW and Family Offices from the South and Southwest in the heart of Austin, Texas.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_main.jpg',
    icon: <CoinsIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_bg.jpg',
  },
  {
    id: 2,
    title: 'Breeders Cup',
    placeAndDate: 'Delmar, California | November 1st-2nd, 2024',
    description:
      "The 2024 Breeders' Cup World Championships, consisting of 14 Grade 1 Championship races and $31 million in purses and awards.",
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_main.jpg',
    icon: <DiamondIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_bg.jpg',
  },
  {
    id: 3,
    title: 'Abu Dhabi Art Fair',
    placeAndDate: 'Abu Dhabi, UAE | November 20-24th, 2024',
    description:
      'The fair showcases global art, featuring galleries, exhibitions, and installations across the emirate.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_main.jpg',
    icon: <GlobeIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_bg.jpg',
  },
  {
    id: 4,
    title: 'Palm Beach Yacht Week',
    placeAndDate: 'Palm Beach, Florida | March 20-23, 2025',
    description:
      'Throughout the 4-day spectacle, guests will be treated to the latest in yacht and superyacht designs, sportfishing boats, cruisers.',
    imageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_main.jpg',
    icon: <ChartIcon />,
    backgroundImageUrl:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/4_bg.jpg',
  },
];
