import FirstImage from './images/1.jpg';
import SecondImage from './images/2.jpg';
import ThirdImage from './images/3.png';

interface EventCard {
  id: number;
  title: string;
  performedAt: string;
  image: string;
}

export const eventCards: EventCard[] = [
  {
    id: 1,
    title: 'Singapore',
    performedAt: 'September 13, 2023',
    image: FirstImage,
  },
  {
    id: 2,
    title: 'Austin',
    performedAt: 'October 11, 2023',
    image: SecondImage,
  },
  {
    id: 3,
    title: 'Palm Beach',
    performedAt: 'January 30, 2024',
    image: ThirdImage,
  },
];
