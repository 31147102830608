import Slider from 'react-slick';
import { useState } from 'react';
import { settings, sliderData } from './Gallery.data';
import styles from './Gallery.module.scss';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const nextImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === sliderData.length - 1) {
        setSelectedImage(0);
      } else {
        setSelectedImage(selectedImage + 1);
      }
    }
  };

  const prevImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === 0) {
        setSelectedImage(sliderData.length - 1);
      } else {
        setSelectedImage(selectedImage - 1);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {sliderData.map((item, index) => (
          <img
            src={item}
            alt=""
            key={index}
            className={styles.image}
            onClick={() => {
              setSelectedImage(index);
            }}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
