import cs from 'classnames';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormState,
  useWatch,
} from 'react-hook-form';
import styles from './InputSubmitV2.module.scss';
import { InputFieldProps } from '../InputField/InputField.type';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export enum InputSubmitTheme {
  WHITE = 'white',
  BLACK = 'black',
}

type InputSubmitProps<T extends FieldValues> = InputFieldProps & {
  theme: InputSubmitTheme;
  control: Control<T>;
  buttonText: string;
  onSubmitClick: () => void;
};

const InputSubmitV2 = <T extends FieldValues>(props: InputSubmitProps<T>) => {
  const [focused, setFocused] = useState(false);
  const { errors } = useFormState<T>({ control: props && props.control });

  return (
    <div className={styles.outerContainer}>
      <div
        className={cs({
          [styles.error]: errors[props.name],
          [styles.outlineOuterContainerError]: errors[props.name] && focused,
          [styles.inputOuterWhiteContainer]:
            props.theme === InputSubmitTheme.WHITE,
          [styles.inputOuterBlackContainer]:
            props.theme === InputSubmitTheme.BLACK,
          [styles.outlineOuterContainerWhite]:
            focused && props.theme === InputSubmitTheme.WHITE,
          [styles.outlineOuterContainerBlack]:
            focused && props.theme === InputSubmitTheme.BLACK,
        })}
      >
        <Controller
          name={props.name as Path<T>}
          control={props.control}
          render={({
            field: { onChange, disabled, value },
            fieldState: { error },
          }) => (
            <input
              className={cs({
                [styles.inputWhite]: props.theme === InputSubmitTheme.WHITE,
                [styles.inputBlack]: props.theme === InputSubmitTheme.BLACK,
              })}
              disabled={disabled}
              placeholder={props.placeholder}
              value={value}
              onChange={onChange}
              name={props.name}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          )}
        />
        <button
          className={cs({
            [styles.buttonWhite]: props.theme === InputSubmitTheme.WHITE,
            [styles.buttonBlack]: props.theme === InputSubmitTheme.BLACK,
          })}
          type="button"
          onClick={props.onSubmitClick}
        >
          {props.buttonText}
        </button>
      </div>
      {errors[props.name] && (
        <span className={styles.errorMessage}>
          {String(errors[props.name]?.message)}
        </span>
      )}
    </div>
  );
};

export default InputSubmitV2;
