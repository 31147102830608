import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import './style.scss';

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'secondaryGreyBorder'
  | 'secondaryForBg'
  | 'inlineDark'
  | 'inlineBlue'
  | 'grey'
  | 'greyBlack'
  | 'iconBtn'
  | 'iconBtnBlack'
  | 'primaryBlack'
  | 'secondaryBlack'
  | 'inlineLight'
  | 'inlineGrey'
  | 'gia'
  | 'igi'
  | 'fcrf'
  | 'sec'
  | 'esg'
  | 'iconBtnTransparent'
  | 'buttonTransparentBlackText'
  | 'buttonTransparentWhiteText'
  | 'secondaryGrey'
  | 'secondaryBlackTransparent'
  | 'iconBtnPrimaryBackground'
  | 'inlineDarkAlternative'
  | 'primaryMedium'
  | 'primaryLarge'
  | 'thirdGreyAlternative'
  | 'iconBtnPrimaryBackgroundWithoutIcon'
  | 'iconBtnPrimaryBackgroundBig'
  | 'whiteBtnTransparent'
  | 'buttonWhiteTillText';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  btnStyle?: ButtonStyle;
  size?: 'long';
}

export const CustomButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, type = 'button', btnStyle = 'primary', size, ...props },
    ref
  ) => {
    const commonClassName = `common-style ${btnStyle}`;

    const withSizeClassName = size
      ? `${commonClassName} ${size}`
      : commonClassName;

    const haveIcon = () =>
      [
        'iconBtn',
        'iconBtnPrimaryBackground',
        'iconBtnTransparent',
        'iconBtnBlack',
        'sec',
        'isg',
        'iconBtnPrimaryBackgroundBig',
      ].includes(btnStyle);

    return (
      <button ref={ref} type={type} className={withSizeClassName} {...props}>
        {haveIcon() && <div className={'icon'}></div>}
        {children}
      </button>
    );
  }
);
