import { Link } from 'react-router-dom';

import LuxusExperienceLogoTextWhite from 'assets/icons/LuxusExperienceLogoTextWhite';
import LuxusExperienceLogoTextBlack from 'assets/icons/LuxusExperienceLogoTextBlack';

import { ROUTES } from 'utils/constants';

import styles from './Header.module.scss';

type HeaderProps = {
  isBlackLogo?: boolean;
};

const Header = ({ isBlackLogo = false }: HeaderProps) => {
  return (
    <header className={styles.header}>
      <a href={process.env.REACT_APP_HOME_URL} target="_self" rel="noreferrer">
        {isBlackLogo ? (
          <LuxusExperienceLogoTextBlack />
        ) : (
          <LuxusExperienceLogoTextWhite />
        )}
      </a>
    </header>
  );
};

export default Header;
