import React, { FC, useEffect, useState } from 'react';
import { getCountriesWithCodes } from 'utils/GetAllPhonePrefix';
import {
  CountryCodeType,
  PhoneDropDownProps,
  PhoneDropDownType,
} from './PhoneDropDown.type';
import {
  Button,
  Container,
  Header,
  Input,
  ListContainer,
} from './styledComponents';
import { phoneDropDownStyles } from './styles';

const PhoneDropDown: FC<PhoneDropDownProps> = ({
  theme,
  onChange,
  color,
  borderColor,
  value,
  error,
  placeholder,
  buttonType = 'submit',
  placeholderColor,
}) => {
  const [countries, setCountries] = useState<CountryCodeType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<CountryCodeType>();
  const [inputValue, setInputValue] = useState('');
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: CountryCodeType) => () => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = value.replace(/[^\d\s+-]/g, '');
    const code = selectedOption?.code;
    if (code && formattedValue.length + code.length + 1 <= 15) {
      setInputValue(formattedValue);
      onChange(`${selectedOption?.code}${formattedValue}`);
    }
  };

  useEffect(() => {
    if (countries.length === 0) {
      const result = getCountriesWithCodes();
      setCountries(result);
      setSelectedOption(result[217]);
      setInputValue(value?.slice(result[217].code.length) ?? '');
    }
  }, []);

  return (
    <Container
      isOpen={isOpen}
      style={{
        borderColor: error
          ? '#cd263a'
          : borderColor || (theme === 'white' ? '#000' : '#7d7d7d'),
        color: color,
      }}
    >
      <Header theme={theme}>
        <Button
          onClick={toggling}
          isOpen={isOpen}
          type={buttonType}
          color={theme}
        >
          {/* {selectedOption?.flag}{" "} */}
          <img
            src={selectedOption?.flag}
            alt={selectedOption?.name}
            style={{ width: '24x', height: '18px' }}
          />
          <span style={{ padding: '0px 0px 0px 10px' }}>+</span>
          {selectedOption?.code}
        </Button>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Input
            type="text"
            placeholder={placeholder || 'PHONE NUMBER'}
            isOpen={isOpen}
            theme={theme}
            value={inputValue}
            onChange={onChangeHandler}
            placeholderColor={placeholderColor}
          />
        </div>
      </Header>
      <ListContainer isOpen={isOpen}>
        {countries.map((option) => (
          <li
            onClick={onOptionClicked(option)}
            key={option.name}
            style={phoneDropDownStyles.listItem}
          >
            {/* <span>{option.flag}</span>{" "} */}
            <img
              src={option.flag}
              alt={option.name}
              style={{ width: '24x', height: '18px' }}
              loading="lazy"
            />
            <span style={{ padding: '0vw 1vw' }}>{option.name}</span>
            <span>+{option.code}</span>
          </li>
        ))}
      </ListContainer>
    </Container>
  );
};

export const PhoneDropDownComponent = {
  Black: (props: PhoneDropDownType) => (
    <PhoneDropDown
      theme="black"
      onChange={props.onChange}
      color={props.color}
      borderColor={props.borderColor}
      value={props.value}
      error={props.error}
      placeholder={props.placeholder}
      buttonType={props.buttonType}
      placeholderColor={props.placeholderColor}
    />
  ),
  White: (props: PhoneDropDownType) => (
    <PhoneDropDown
      theme="white"
      onChange={props.onChange}
      color={props.color}
      borderColor={props.borderColor}
      value={props.value}
      error={props.error}
      placeholder={props.placeholder}
      buttonType={props.buttonType}
    />
  ),
};
