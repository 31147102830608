import { Route, Routes } from 'react-router-dom';

import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from 'pages/TermsOfService/TermsOfService';

import './App.css';
import './FuturaFont.css';
import './TeluguFont.css';
import { ROUTES } from 'utils/constants';
import HomePage from 'modules/HomePage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfService />} />
      </Routes>
    </div>
  );
}

export default App;
