import cs from 'classnames';
import './card.scss';

export interface CardProps {
  title: string;
  description: string;
  placeAndDate: string;
  imageUrl: string;
  icon: React.ReactNode;
  isActive?: boolean;
}

export const Card: React.FC<CardProps> = ({
  title,
  description,
  imageUrl,
  icon,
  isActive,
  placeAndDate,
}) => {
  return (
    <div className={cs('card', { active: isActive })}>
      {isActive ? (
        <>
          <div className="card-content">
            <h3 className="card-title">{title}</h3>
            <p className="card-place">{placeAndDate}</p>
          </div>
          <div className="card-image-container">
            <img src={imageUrl} alt={title} className="card-image" />
          </div>
          <div className="card-content">
            <p className="card-description">{description}</p>
          </div>
        </>
      ) : (
        <div className="card-timeline">{placeAndDate}</div>
      )}
    </div>
  );
};
