import { eventCards } from './constants';
import EventCard from './EventCard';
import styles from './PreviousEvents.module.scss';

const PreviousEvents = () => {
  return (
    <div className={styles.section}>
      <h2 className={styles.title}>Previous Events</h2>
      <div className={styles.cardContainer}>
        {eventCards.map((card, index) => (
          <EventCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default PreviousEvents;
