import axios from 'axios';

const baseurl = process.env.REACT_APP_BACKEND_BASE_URL || '';

export type HubSpotProfileData = {
  email: string;
  firstName?: string;
  lastName?: string;
  ipAddress: string;
  trackingCookie: string;
  pageUri: string;
  pageName: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  titleCompanyName?: string;
  accessibilityNeeds?: string;
  postcode?: string;
  citizenshipStatusAlt?: string;
};

export const getHubSpotCookie = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
};

export const sendHubSpotData = async (
  userData: HubSpotProfileData,
  formId: string
) => {
  const response = await axios.post(
    `${baseurl}/v1/hubspot/forms/${formId}/submit`,
    { ...userData, email: userData.email.replaceAll(' ', '') },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data;
};
