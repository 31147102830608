import { useMediaQuery } from '@mui/material';
import styles from './VideoBlock.module.scss';

type VideoBlockProps = {
  link: string;
};

const VideoBlock = ({ link }: VideoBlockProps) => {
  const isTablet = useMediaQuery('(max-width: 880px)');
  const isWideScreen = useMediaQuery('(min-width:2440px)');
  return (
    <div className={styles.videoContainer}>
      <iframe
        title="Experiences"
        src={`${link}?h=17ad83c8c3&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1`}
        width="3840"
        height="2160"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          overflow: 'hidden',
          overflowX: 'hidden',
          overflowY: 'hidden',
          zIndex: 1,
          height: isTablet ? '100%' : '',
          width: isTablet ? '' : '100%',
          position: 'absolute',
          top: isWideScreen ? '-100%' : isTablet ? '0px' : '-850px',
        }}
      />
    </div>
  );
};

export default VideoBlock;
