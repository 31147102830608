const ChartIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M7.22461 19.3789L13.125 13.4785L18.0861 18.4396C18.4522 18.8057 19.0459 18.8057 19.4115 18.4398L27.849 10.055C28.2152 9.68891 28.2152 9.09523 27.8491 8.72961C27.483 8.36398 26.8894 8.36352 26.5238 8.72961L18.75 16.4551L13.7871 11.4922C13.4225 11.1274 12.8262 11.1274 12.4611 11.4921L5.89863 18.0546C5.53336 18.4198 5.53336 19.0149 5.89863 19.38C6.26367 19.748 6.86133 19.748 7.22461 19.3789ZM29.0625 27.1543H4.6875C3.13652 27.1543 1.875 25.8945 1.875 24.3418V3.7168C1.875 3.19941 1.45488 2.7793 0.9375 2.7793C0.420117 2.7793 0 3.19941 0 3.7168V24.3418C0 26.9258 2.10234 29.0293 4.6875 29.0293H29.0625C29.5798 29.0293 30 28.6091 30 28.0918C30 27.5745 29.5781 27.1543 29.0625 27.1543Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default ChartIcon;
