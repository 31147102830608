const DiamondIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M29.83 12.5527L23.2675 3.17773C23.0917 2.92871 22.8046 2.7793 22.4999 2.7793H7.4472C7.19525 2.7793 6.90814 2.92871 6.73236 3.17949L0.169859 12.5545C-0.0846727 12.9179 -0.049868 13.4094 0.252241 13.7328L14.3147 28.7328C14.496 28.9238 14.7421 29.0293 14.9999 29.0293C15.2577 29.0293 15.5059 28.9222 15.6837 28.7327L29.7462 13.7327C30.0527 13.4082 30.082 12.916 29.83 12.5527ZM22.4648 5.30645L27.2636 12.1543H17.2499L22.4648 5.30645ZM14.9999 12.0137L9.39252 4.6543H20.6074L14.9999 12.0137ZM7.53509 5.30645L12.7499 12.1543H2.73802L7.53509 5.30645ZM14.9999 26.7207L3.1013 14.0293H26.8962L14.9999 26.7207Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default DiamondIcon;
