type ArrowRightProps = {
  color?: string;
};

const ArrowRight: React.FC<ArrowRightProps> = ({ color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="23"
      viewBox="0 0 13 23"
      fill="none"
    >
      <path
        d="M1.02552 21.3834L11.3833 11.3591L1.35904 1.00135"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowRight;
