import { Radio } from "@mui/material";
import { styled } from "@mui/system";
import { StyledRadioProps } from "./RadioButton.type";

export const BlackStyledRadio = styled(Radio)<StyledRadioProps>(
  ({ error }) => ({
    color: error === "true" ? "red" : "#000",
    "&.Mui-checked": {
      color: error === "true" ? "red" : "#000",
    },
    "& .MuiSvgIcon-root": { fontSize: 34 },
  })
);

export const WhiteStyledRadio = styled(Radio)<StyledRadioProps>(
  ({ error }) => ({
    color: error === "true" ? "red" : "#fff",

    "&.Mui-checked": {
      color: error === "true" ? "red" : "#fff",
    },
    "& .MuiSvgIcon-root": { fontSize: 34 },
  })
);
