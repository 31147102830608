export const getIPFromAmazon = (): Promise<string> => {
  return fetch('https://api.ipify.org/?format=json')
    .then((res) => {
      return res.json();
    })
    .then((data) => data.ip ?? '')
    .catch((error) => {
      console.error(error);
      return '';
    });
};
