type ArrowLeftProps = {
  color?: string;
};

const ArrowLeft: React.FC<ArrowLeftProps> = ({ color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="23"
      viewBox="0 0 14 23"
      fill="none"
    >
      <path
        d="M12.1924 0.999999L2 11.1924L12.1924 21.3848"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowLeft;
