import dayjs from 'dayjs';
import styles from './EventCard.module.scss';

export type EventCardProps = {
  image: string;
  title: string;
  performedAt: string;
};

const EventCard = (props: EventCardProps) => {
  const { image, title, performedAt } = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.imageContainer}>
        <img
          src={image}
          className={styles.image}
          alt={title}
          width={350}
          height={250}
        />
      </div>
      <div className={styles.cardInfo}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <div className={styles.cardAdditionalInfo}>
          <span className={styles.cardDate}>
            {dayjs(performedAt).format('MMMM D, YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
