import { FC, ReactNode } from 'react';
import { Box, Link, Typography } from '@mui/material';
import BaseLayout from 'layout/BaseLayout/baseLayout';

import { PrivacyLinkProps } from './PrivacyPolicy.type';
import { MAIN_URL, ROUTES } from 'utils/constants';

const PrivacyTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      component="p"
      lineHeight={'30px'}
      fontFamily={'Futura PT'}
      letterSpacing={'1px'}
    >
      {children}
    </Typography>
  );
};

const PrivacyText: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      lineHeight={'20.51px'}
      fontFamily={'Futura PT'}
      fontSize={'16px'}
      letterSpacing={'1px'}
    >
      {children}
    </Typography>
  );
};

const PrivacyLink: FC<PrivacyLinkProps> = ({ href, children, props }) => (
  <Link
    href={href}
    {...props}
    sx={{
      textDecoration: 'underline',
      color: 'inherit',
    }}
  >
    {children}
  </Link>
);

const PrivacyPolicy = () => {
  return (
    <BaseLayout
      pageName="Privacy policy page"
      pageUri={`${MAIN_URL}${ROUTES.PRIVACY_POLICY}`}
      color="black"
      isBlackLogo
    >
      <Box
        maxWidth={'1040px'}
        margin={'0 auto'}
        padding={'180px 20px 160px 20px'}
      >
        <Typography
          component="p"
          textAlign="center"
          fontFamily={'Telugu MN'}
          lineHeight={'48.57px'}
          fontWeight={400}
          fontSize={'40px'}
          margin={'0px 0px 20px 0px'}
        >
          PRIVACY POLICY
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={'30px'}>
          <PrivacyText>Last Updated June 14, 2024</PrivacyText>
          <PrivacyText>
            Welcome to the website (the “Site”) of LUXUS Experiences LLC
            (“Luxus,” “we,” “us,” or “our”). We partner with premium brands to
            provide fractional ownership opportunities in precious gems, coveted
            jewelry, rare watches, and other bespoke accessories and luxury
            assets (collectively, including the Site and any related products
            and services, the “Service”).
          </PrivacyText>
          <PrivacyText>
            This Privacy Policy explains what Personal Data we collect, how we
            use and share that data, and your choices concerning our data
            practices. Before using the Service or submitting any Personal Data
            to us, please review this Privacy Policy carefully and contact us at{' '}
            <PrivacyLink href="mailto:privacy@luxusexperiences.co">
              privacy@luxusexperiences.co
            </PrivacyLink>{' '}
            if you have any questions. By using the Service, you agree to the
            practices described in this Privacy Policy. If you do not agree to
            this Privacy Policy, you may not access, use, or participate in the
            Service.
          </PrivacyText>
          <PrivacyTitle>
            1. PERSONAL DATA WE COLLECT{' '}
            <PrivacyText>
              We collect information that alone or in combination with other
              information in our possession personally identifies you (“Personal
              Data”) as follows: Personal Data You Provide: We collect Personal
              Data when you visit the Site, register to be added to our waiting
              list or to otherwise use the Service, in connection with your use
              of the Service, and in other instances where you may provide it to
              us. The Personal Data collected during these interactions may vary
              based on what you choose to share with us, but it will generally
              include your name and email address. We may also collect certain
              payment and financial information in connection with the Service.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyText>
            Personal Data We Receive Automatically From Your Use of the Service:
            When you visit, use and interact with the Service, we may receive
            certain information about your visit, use or interactions. For
            example, we may monitor the number of people that visit the Service,
            peak hours of visits, which page(s) are visited, the domains our
            visitors come from (e.g., google.com, etc.), and which browsers
            people use to access the Service (e.g., Google Chrome, Microsoft
            Edge, etc.), and geographical location information. In particular,
            the following information may be created and automatically logged in
            our systems:
          </PrivacyText>
          <PrivacyText>
            Log data: Information that your browser automatically sends whenever
            you visit the Service (“Log Data”). Log Data includes your Internet
            Protocol address, browser type and settings, the date and time of
            your request, and how you interacted with the Service.
          </PrivacyText>
          <PrivacyText>
            Cookies: Please see the “Cookies” section below to learn more about
            how we use cookies.
          </PrivacyText>
          <PrivacyText>
            Device information: Includes name of the device, operating system,
            and browser you are using. Information collected may depend on the
            type of device you use and its settings.
          </PrivacyText>
          <PrivacyText>
            Usage Information: We collect information about how you use our
            Service, such as the types of content that you view or engage with,
            the features you use, the actions you take, and the time, frequency
            and duration of your activities.
          </PrivacyText>
          <PrivacyText>
            Cookies: We use cookies to operate and administer our Service,
            gather usage data on our Service and improve your experience on it.
            A “cookie” is a piece of information sent to your browser by a
            website you visit. Cookies can be stored on your computer for
            different periods of time. Some cookies expire after a certain
            amount of time, or upon logging out (session cookies), others
            survive after your browser is closed until a defined expiration date
            set in the cookie (as determined by the third party placing it), and
            help recognize your computer when you open your browser and browse
            the Internet again (persistent cookies). For more details on cookies
            please visit All About Cookies.
          </PrivacyText>
          <PrivacyText>
            Analytics: We may use certain third-party analytics providers,
            including Google Analytics, a web analytics service provided by
            Google, Inc. (“Google”). Google Analytics uses cookies to help us
            analyze how users use the Service and enhance your experience when
            you use the Service. For more information on how Google uses this
            data, go to{' '}
            <PrivacyLink
              props={{
                target: '_blank',
                rel: 'noopener noreferrer',
              }}
              href="https://www.google.com/policies/privacy/partners/"
            >
              www.google.com/policies/privacy/partners/
            </PrivacyLink>
            .
          </PrivacyText>
          <PrivacyText>
            Online Tracking and Do Not Track Signals: We and our third-party
            service providers, including Google, Facebook, and Instagram, may
            use cookies, pixels or other tracking technologies to collect
            information about your browsing activities over time and across
            different websites following your use of the Service and use that
            information to send targeted advertisements. Our Service currently
            does not respond to “Do Not Track” (“DNT”) signals and operates as
            described in this Privacy Policy whether or not a DNT signal is
            received. If we do respond to DNT signals in the future, we will
            update this Privacy Policy to describe how we do so.
          </PrivacyText>
          <PrivacyText>
            Your Choices. On most web browsers, you will find a “help” section
            on the toolbar. Please refer to this section for information on how
            to receive a notification when you are receiving a new cookie and
            how to turn cookies off. Please see the links below for guidance on
            how to modify your web browser’s settings on the most popular
            browsers:
          </PrivacyText>
          <ul>
            <li>Internet Explorer</li>
            <li>Mozilla Firefox</li>
            <li>Google Chrome</li>
            <li>Apple Safari</li>
          </ul>
          <PrivacyText>
            Please note that if you limit the ability of websites to set
            cookies, you may be unable to access certain parts of the Service
            and you may not be able to benefit from the full functionality of
            the Service.
          </PrivacyText>
          <PrivacyText>
            Advertising networks may collect Personal Data through the use of
            cookies. Most advertising networks offer you a way to opt out of
            targeted advertising. If you would like to find out more
            information, please visit the Network Advertising Initiative’s
            online resources at{' '}
            <PrivacyLink href="http://www.networkadvertising.org">
              http://www.networkadvertising.org
            </PrivacyLink>{' '}
            and follow the opt-out instructions there.
          </PrivacyText>
          <PrivacyText>
            If you access the Service on your mobile device, you may not be able
            to control tracking technologies through the settings.
          </PrivacyText>
          <PrivacyTitle>
            2. HOW WE USE PERSONAL DATA{' '}
            <PrivacyText>
              We may use Personal Data for the purpose you provided it, as well
              as for the following purposes: To provide and facilitate your
              engagement with the Service, including to support, process, and
              validate your investment in assets made available on the
              Service;To respond to your inquiries, comments, feedback or
              questions;To send administrative information to you, for example,
              information regarding the Service, and changes to our terms,
              conditions, and policies;To administer a promotion, contest,
              sweepstakes, survey or other Service feature;To analyze how you
              interact with our Service;To maintain and improve the content and
              functionality of the Service;To develop new products and
              services;To prevent fraud, criminal activity, or misuses of our
              Service, and to ensure the security of our IT systems,
              architecture and networks; andTo comply with legal obligations and
              legal process and to protect our rights, privacy, safety or
              property, and/or that of our affiliates, you or other third
              parties.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyText>
            Aggregated or Anonymized Information. We may collect or generate
            aggregated and/or anonymized Personal Data and use the aggregated
            and/or anonymized information to analyze the effectiveness of our
            Service, to improve and add features to our Service, and for any
            other lawful purpose. In addition, we may share aggregated and/or
            anonymized information with our business partners and other third
            parties. We may collect or generate aggregated and/or anonymized
            information through the Service, through cookies, and through other
            means described in this Privacy Policy.
          </PrivacyText>
          <PrivacyText>
            Marketing. We may use your Personal Data to contact you to tell you
            about products or services we believe may be of interest to you. For
            instance, if you elect to provide your email or telephone number, we
            may use that information to send you certain offers. You may opt out
            of receiving emails by following the instructions contained in each
            promotional email we send you. In addition, if at any time you do
            not wish to receive future marketing communications, you may contact
            us at{' '}
            <PrivacyLink href="mailto:privacy@luxusexperiences.co">
              privacy@luxusexperiences.co
            </PrivacyLink>
            . If you unsubscribe from our marketing lists you will no longer
            receive marketing communications, but we will continue to contact
            you regarding management of your account, other administrative
            matters, and to respond to your requests.
          </PrivacyText>
          <PrivacyTitle>
            3. SHARING AND DISCLOSURE OF PERSONAL DATA{' '}
            <PrivacyText>
              In certain circumstances we may share your Personal Data with
              third parties without further notice to you, unless required by
              the law, as set forth below: Vendors, Service Providers, and
              Partners: To assist us in meeting business operations needs and to
              perform certain services and functions, we may share Personal Data
              with vendors and service providers, including providers of hosting
              services, cloud services and other information technology services
              providers, ecommerce services, KYC services, order fulfillment
              services, event management services, email communication software
              and email newsletter services, advertising and marketing services,
              financial services providers and payment processors, customer
              relationship management and customer support services, and web
              analytics services (for more details on the third parties that
              place cookies through the Service, please see the “Cookies”
              section above). We may also share Personal data with our partners,
              including those from whom we source our luxury assets.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyText>
            Business Transfers: If we are involved in a merger, acquisition,
            financing due diligence, reorganization, bankruptcy, receivership,
            sale of all or a portion of our assets, or transition of service to
            another provider, your Personal Data and other information may be
            shared in the diligence process with counterparties and others
            assisting with the transaction and transferred to a successor or
            affiliate as part of that transaction along with other assets.
          </PrivacyText>
          <PrivacyText>
            Legal Requirements: If required to do so by law or in the good faith
            belief that such action is necessary to (i) comply with a legal
            obligation, including to meet national security or law enforcement
            requirements, (ii) protect and defend our rights or property, (iii)
            prevent fraud, (iv) act in urgent circumstances to protect the
            personal safety of users of the Service, or the public, or (v)
            protect against legal liability.
          </PrivacyText>
          <PrivacyText>
            Affiliates: We may share Personal Data with our affiliates, meaning
            an entity that controls, is controlled by, or is under common
            control with Luxus. Our affiliates may use the Personal Data we
            share in a manner consistent with this Privacy Policy.
          </PrivacyText>
          <PrivacyTitle>
            4. DATA RETENTION{' '}
            <PrivacyText>
              We keep Personal Data for as long as you use the Service or as
              reasonably necessary or advisable to fulfill the purpose(s) for
              which it was collected, provide the Service, resolve disputes,
              establish legal defenses, conduct audits, pursue legitimate
              business purposes, protect against fraud, enforce our agreements,
              and comply with applicable laws.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            5. UPDATE YOUR INFORMATION{' '}
            <PrivacyText>
              Please contact us at{' '}
              <PrivacyLink href="mailto:privacy@luxusexperiences.co">
                privacy@luxusexperiences.co
              </PrivacyLink>{' '}
              if you need to change or correct your Personal Data.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            6. CHILDREN{' '}
            <PrivacyText>
              Our Service is not directed to children who are under the age of
              18. Luxus does not knowingly collect Personal Data from children
              under the age of 13. If you have reason to believe that a child
              under the age of 13 has provided Personal Data to us through the
              Service please contact us at{' '}
              <PrivacyLink href="mailto:privacy@luxusexperiences.co">
                privacy@luxusexperiences.co
              </PrivacyLink>
              . and we will endeavor to delete that information from our
              databases.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            7. LINKS TO OTHER WEBSITES AND THIRD PARTY FUNCTIONALITY{' '}
            <PrivacyText>
              The Service may contain links to other websites or applications,
              or other functionality not operated or controlled by Luxus,
              including certain social media integrations (e.g. Facebook
              sharing) and banking or financial services (“Third-Party
              Services”). You will generally have discretion and control over
              the Third-Party Services with whom you elect to interact and share
              your Personal Data, except as otherwise provided in this Privacy
              Policy. These Third-Party Services include certain payment
              processors or other API finance solutions. The information that
              you share with Third-Party Services will be governed by the
              specific privacy policies and terms of service of the Third-Party
              Services and not by this Privacy Policy. By providing these links
              or functionality we do not imply that we endorse or have reviewed
              these services. Please contact the Third-Party Services directly
              for information on their privacy practices and policies.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            8. SECURITY{' '}
            <PrivacyText>
              You use the Service at your own risk. We implement commercially
              reasonable technical, administrative, and organizational measures
              designed to protect Personal Data both online and offline from
              loss, misuse, and unauthorized access, disclosure, alteration or
              destruction. However, no Internet or e-mail transmission is ever
              fully secure or error free. In particular, e-mail sent to or from
              us may not be secure. Therefore, you should take special care in
              deciding what information you send to us via the Service or
              e-mail. Please keep this in mind when disclosing any Personal Data
              to us via the Internet. In addition, we are not responsible for
              circumvention of any privacy settings or security measures
              contained on the Service, or third-party websites.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            9. YOUR CHOICES{' '}
            <PrivacyText>
              In certain circumstances providing Personal Data is optional.
              However, if you choose not to provide Personal Data that is needed
              to use some features of our Service, you may be unable to use
              those features.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            10. INTERNATIONAL DATA TRANSFERS{' '}
            <PrivacyText>
              All information processed by us may be transferred, processed, and
              stored anywhere in the world, including but not limited to, the
              United States and other countries. If you are accessing our
              Service from the European Economic Area (“EEA”) or other regions
              with laws governing data collection and use, please note that your
              Personal Data will be transferred to and stored in the United
              States as necessary for the purposes described in this Privacy
              Policy, and Personal Data may be transmitted to our service
              providers or partners supporting our business operations
              (described above). The United States may have data protection laws
              less stringent than or otherwise different from the laws in effect
              in the country in which you are located.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            11. CHANGES TO THE PRIVACY POLICY{' '}
            <PrivacyText>
              The Service, and our business may change from time to time. As a
              result, we may change this Privacy Policy at any time. When we do,
              we will post an updated version on this page, unless another type
              of notice is required by the applicable law. By continuing to use
              our Service or providing us with Personal Data after we have
              posted an updated Privacy Policy, or notified you by other means
              if applicable, you consent to the revised Privacy Policy and
              practices described in it.
            </PrivacyText>
          </PrivacyTitle>

          <PrivacyTitle>
            12. CONTACT US{' '}
            <PrivacyText>
              If you have any questions about our Privacy Policy or information
              practices, please feel free to contact us at{' '}
              <PrivacyLink href="mailto:privacy@luxusexperiences.co">
                privacy@luxusexperiences.co
              </PrivacyLink>
              .
            </PrivacyText>
          </PrivacyTitle>
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default PrivacyPolicy;
