import { useState } from 'react';

import JoinForm, { HubspotForms } from './JoinForm/JoinForm';
import ModalJoinSubscribed from './ModalJoinSubscribed';

import cs from 'classnames';

import styles from './ModalJoin.module.scss';
import { CustomButton } from 'components/UIComponents/Button';
import LogoOrange from 'assets/icons/LogoOrange';
import RoyalAscotForm from './JoinForm/RoyalAscotForm';
import { useSwiper } from 'swiper/react';

export enum ModalTypes {
  EVENTS = 'events',
  ROYAL_ASCOT = 'royal_ascot',
}

type ModalJoinProps = {
  handleModal: () => void;
  formName: HubspotForms;
  modalType?: ModalTypes;
};

const ModalJoin: React.FC<ModalJoinProps> = ({
  handleModal,
  formName,
  modalType = ModalTypes.EVENTS,
}) => {
  const swiper = useSwiper();

  const handleSlider = () => {
    swiper.autoplay.start();
  };
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);

  return (
    <div
      className={cs({
        [styles.wrapper]: modalType === ModalTypes.EVENTS,
        [styles.wrapperBgAustin]: formName === HubspotForms.AUSTIN,
        [styles.wrapperBgAbuDhabi]: formName === HubspotForms.ABU_DHABI,
        [styles.wrapperBgBreeders]: formName === HubspotForms.BREEDERS_CUP,
        [styles.wrapperBgPalmBeach]: formName === HubspotForms.PALM_BEACH,
        [styles.wrapperLeaaf]: modalType === ModalTypes.ROYAL_ASCOT,
      })}
    >
      <div className={styles.logoWrapper}>
        <LogoOrange />
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton
          btnStyle="buttonTransparentWhiteText"
          onClick={() => {
            if (modalType !== ModalTypes.ROYAL_ASCOT) {
              handleSlider();
            }
            handleModal();
          }}
        >
          CLOSE
        </CustomButton>
      </div>
      {modalType === ModalTypes.EVENTS && (
        <div className={styles.modalContentWrapper}>
          <JoinForm
            buttonText="GET ON THE LIST"
            pageName={'LUXUS Experiences'}
            pageUri={`${process.env.NEXT_PUBLIC_RONTEND_BASE_URL}`}
            formName={formName}
          />
        </div>
      )}
      {modalType === ModalTypes.ROYAL_ASCOT && (
        <div className={styles.modalContentWrapperAlt}>
          <RoyalAscotForm
            buttonText="INTERESTED IN Royal Ascot"
            pageName={'Home Page'}
            pageUri={`${process.env.NEXT_PUBLIC_RONTEND_BASE_URL}`}
          />
          <div className={styles.leaafImageContainer}>
            <img
              src={
                'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/join-modal/ra-image.jpg'
              }
              alt=""
              width={800}
              height={600}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalJoin;
