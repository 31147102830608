type ExperiencesSlide = {
  id: number;
  image: string;
};

export const experiencesSlideData: ExperiencesSlide[] = [
  {
    id: 1,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/1.jpg',
  },
  {
    id: 2,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/2.jpg',
  },
  {
    id: 3,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/3.jpg',
  },
  {
    id: 4,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/4.jpg',
  },
  {
    id: 5,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/5.jpg',
  },
  {
    id: 6,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/6.jpg',
  },
  {
    id: 7,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/7.jpg',
  },
  {
    id: 8,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/1.jpg',
  },
  {
    id: 9,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/2.jpg',
  },
  {
    id: 10,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/3.jpg',
  },
  {
    id: 11,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/4.jpg',
  },
  {
    id: 12,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/5.jpg',
  },
  {
    id: 13,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/6.jpg',
  },
  {
    id: 14,
    image:
      'https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/featured-experience/7.jpg',
  },
];
