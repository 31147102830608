import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import { SwiperSlideControls } from 'components/Swiper/SwiperSlideControls';
import styles from './FeaturedExperience.module.scss';
import './sliderStyles.scss';
import { experiencesSlideData } from './constants';
import { Box, Modal, Slide, useMediaQuery } from '@mui/material';
import { CustomButton } from 'components/UIComponents/Button';
import ModalJoin, { ModalTypes } from '../ModalJoin/ModalJoin';
import { HubspotForms } from '../ModalJoin/JoinForm/JoinForm';

const FeaturedExperiences = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    isModalOpen ? setIsModalOpen(false) : setIsModalOpen(true);
  };

  const isSmallScreen = useMediaQuery('(max-width: 1140px)');

  return (
    <div className={styles.experiencesContainer}>
      {isSmallScreen && (
        <div className={styles.controlBlock}>
          <div className={styles.titleGroup}>
            <h2 className={styles.title}>
              FEATURED <span className={styles.till}>EXPERIENCE:</span>
            </h2>
            <h3 className={styles.subTitle}>ROYAL ASCOT 2024</h3>
          </div>
          <p className={styles.description}>
            In 2024, LUXUS investors spent 4 days in London attending the Royal
            Ascot races and experiencing fine art, a jewelry tour at Garrard and
            a private concert featuring Milan Symphony Orchestra conductor,
            Emmanuel Tjeknavorian{' '}
            <a
              className={styles.itineraryLink}
              href="https://d1p3kxhg87s54m.cloudfront.net/royal-ascot/Royal_Ascot_Itinerary.pdf"
              target="_blank"
              rel="noreferrer"
            >
              (view itinerary here)
            </a>
          </p>
          <div className={styles.actionGroupAlt}>
            <p className={styles.ctaDescription}>
              Please provide your interest to join the LUXUS community at Royal
              Ascot when we return June 17-21, 2025!
            </p>
            <CustomButton btnStyle="buttonWhiteTillText" onClick={handleModal}>
              <span className={styles.buttonText}>GET ON THE LIST</span>
            </CustomButton>
          </div>
        </div>
      )}
      <Swiper
        spaceBetween={50}
        loop={true}
        slidesPerView={'auto'}
        direction={'horizontal'}
        centeredSlides={isSmallScreen ? true : false}
        initialSlide={0}
        speed={1000}
        autoplay={{ delay: 2000, disableOnInteraction: true }}
        modules={[Autoplay]}
        onRealIndexChange={(swiper) => setSlideIndex(swiper.realIndex)}
        className="featuredExperiencesSlider"
      >
        {experiencesSlideData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              className={`slideContent ${
                slideIndex === index ? 'activeSlide' : ''
              }`}
            >
              <img src={slide.image} alt="" />
            </div>
          </SwiperSlide>
        ))}
        {isSmallScreen && (
          <div className={styles.controlBlockAlt}>
            <SwiperSlideControls slideIndex={slideIndex} totalSlides={7} />
          </div>
        )}
        {!isSmallScreen && (
          <div className={styles.controlBlock}>
            <div className={styles.titleGroup}>
              <h2 className={styles.title}>
                FEATURED <span className={styles.till}>EXPERIENCE:</span>
              </h2>
              <h3 className={styles.subTitle}>ROYAL ASCOT 2024</h3>
            </div>
            <p className={styles.description}>
              In 2024, LUXUS investors spent 4 days in London attending the
              Royal Ascot races and experiencing fine art, a jewelry tour at
              Garrard and a private concert featuring Milan Symphony Orchestra
              conductor, Emmanuel Tjeknavorian{' '}
              <a
                className={styles.itineraryLink}
                href="https://d1p3kxhg87s54m.cloudfront.net/royal-ascot/Royal_Ascot_Itinerary.pdf"
                target="_blank"
                rel="noreferrer"
              >
                (view itinerary here)
              </a>
            </p>
            <div className={styles.actionGroup}>
              <p className={styles.ctaDescription}>
                Please provide your interest to join the LUXUS community at
                Royal Ascot when we return June 17-21, 2025!
              </p>
              <CustomButton
                btnStyle="buttonWhiteTillText"
                onClick={handleModal}
              >
                <span className={styles.buttonText}>GET ON THE LIST</span>
              </CustomButton>
            </div>
            <SwiperSlideControls slideIndex={slideIndex} totalSlides={7} />
          </div>
        )}
      </Swiper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={handleModal}
      >
        <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              bottom: '0',
              width: '100%',
            }}
          >
            <ModalJoin
              handleModal={handleModal}
              formName={HubspotForms.ROYAL_ASCOT}
              modalType={ModalTypes.ROYAL_ASCOT}
            />
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default FeaturedExperiences;
