import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, SubmitHandler, Controller } from 'react-hook-form';
import styles from './JoinForm.module.scss';
import { countries, nationalityOptions, stateOptions } from './constants';
import { getIPFromAmazon } from 'api/common';
import { getHubSpotCookie, sendHubSpotData } from 'api/hubspot';
import InputFieldV2, {
  InputFieldTheme,
} from 'components/UIComponents/Input/InputField/InputFieldV2';
import RadioButton from 'components/UIComponents/Radio/RadioButton';
import { CustomButton } from 'components/UIComponents/Button';
import ControlledDropdown from 'components/UIComponents/ControlledDropdown/ControlledDropdown';
import ControlledPhoneDropDown from 'components/UIComponents/ControlledPhoneDropDown/ControlledPhoneDropDown';
import { useMediaQuery } from '@mui/material';

enum Nationality {
  US = 'US',
  NON_US = 'Non-US',
}

interface IJoinFormInput {
  firstName: string;
  lastName: string;
  nationality: Nationality;
  email: string;
  country?: string;
  primState?: string;
  isCountryRequired?: boolean;
  isStateRequired?: boolean;
  street: string;
  streetAddressOptional?: string;
  city: string;
  phone: string;
  primZip: string;
}

type JoinFormProps = {
  pageName: string;
  pageUri: string;
  buttonText: string;
};

const defaultValues: IJoinFormInput = {
  firstName: '',
  lastName: '',
  nationality: Nationality.US,
  email: '',
  country: '',
  isCountryRequired: false,
  street: '',
  streetAddressOptional: '',
  city: '',
  phone: '',
  isStateRequired: false,
  primState: '',
  primZip: '',
};

const validationSchema = yup
  .object({
    firstName: yup.string().required('This field is required!'),
    lastName: yup.string().required('This field is required!'),
    nationality: yup
      .mixed<Nationality>()
      .oneOf(Object.values(Nationality))
      .required('This field is required!'),
    street: yup.string().required('This field is required!'),
    city: yup.string().required('This field is required!'),
    primZip: yup.string().required('This field is required!'),
    email: yup
      .string()
      .email('Invalid email address.')
      .required('This field is required!'),
    phone: yup.string().trim().required('This field is required'),
    streetAddressOptional: yup.string(),
    isCountryRequired: yup.boolean(),
    isStateRequired: yup.boolean(),
    country: yup.string().when('isCountryRequired', {
      is: true,
      then: () => yup.string().required('This field is required!'),
      otherwise: () => yup.string().notRequired(),
    }),
    primState: yup.string().when('isStateRequired', {
      is: true,
      then: () => yup.string().required('This field is required!'),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

const RoyalAscotForm: React.FC<JoinFormProps> = ({
  buttonText,
  pageName,
  pageUri,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IJoinFormInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });
  const nationality = useWatch({ control, name: 'nationality' });

  const isMobile = useMediaQuery('(max-width: 575px)');

  const onSubmit: SubmitHandler<IJoinFormInput> = async (data) => {
    try {
      const ip = await getIPFromAmazon();

      const formId =
        process.env.REACT_APP_HUBSPOT_EXPERIENCES_ROYAL_ASCOT_FORM_ID || '';
      const trackingCookie = getHubSpotCookie() || '';

      const response = await sendHubSpotData(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          state: data.primState,
          address: `${data.street} ${data.streetAddressOptional || ''}`,
          city: data.city,
          citizenshipStatusAlt: data.nationality,
          phoneNumber: `+${data.phone}`,
          country: data.country,
          ipAddress: ip,
          trackingCookie,
          pageUri,
          pageName,
        },
        formId
      );

      if (response.inlineMessage === 'Thanks for submitting the form.') {
        return Promise.resolve();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.leaafFormContainer}>
      <form className={styles.leaafForm}>
        <div className={styles.formContainerAlt}>
          {!isSubmitSuccessful && (
            <>
              <div className={styles.contentGroup}>
                <h2 className={styles.contentTitle}>Royal Ascot 2025</h2>
                <p className={styles.contentSubTitle}>
                  Please provide your interest in joining LUXUS at Royal Ascot
                  2025 by completing the information below
                </p>
              </div>
              <div className={styles.inputGroupContainer}>
                {isMobile ? (
                  <>
                    <div className={styles.inputGroupRow}>
                      <InputFieldV2<IJoinFormInput>
                        theme={InputFieldTheme.WHITE}
                        placeholder={'FIRST NAME'}
                        name="firstName"
                        control={control}
                        fullWidth={true}
                      />
                    </div>
                    <div className={styles.inputGroupRow}>
                      <InputFieldV2<IJoinFormInput>
                        theme={InputFieldTheme.WHITE}
                        placeholder={'LAST NAME'}
                        name="lastName"
                        control={control}
                        fullWidth={true}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.inputGroupRow}>
                    <InputFieldV2<IJoinFormInput>
                      theme={InputFieldTheme.WHITE}
                      placeholder={'FIRST NAME'}
                      name="firstName"
                      control={control}
                    />
                    <InputFieldV2<IJoinFormInput>
                      theme={InputFieldTheme.WHITE}
                      placeholder={'LAST NAME'}
                      name="lastName"
                      control={control}
                    />
                  </div>
                )}
                <div className={styles.inputGroupRow}>
                  <div style={{ width: '100%' }}>
                    <ControlledDropdown
                      placeholderColor="#fff"
                      borderColor="#fff"
                      arrowColor="#fff"
                      isError={!!errors.nationality}
                      errorMessage={errors.nationality?.message}
                      placeholder="NATIONALITY"
                      name="nationality"
                      dropdownOptions={nationalityOptions}
                      control={control}
                      initValue={{
                        label: 'NATIONALITY',
                        value: 'nationality',
                      }}
                    />
                  </div>
                </div>
                {isMobile ? (
                  <>
                    <div className={styles.inputGroupRow}>
                      <InputFieldV2<IJoinFormInput>
                        theme={InputFieldTheme.WHITE}
                        placeholder={'STREET ADDRESS'}
                        name="street"
                        control={control}
                        fullWidth={true}
                      />
                    </div>
                    <div className={styles.inputGroupRow}>
                      {nationality === Nationality.US ? (
                        <InputFieldV2<IJoinFormInput>
                          theme={InputFieldTheme.WHITE}
                          placeholder={'ADDRESS LINE 2'}
                          name="streetAddressOptional"
                          control={control}
                          fullWidth={true}
                        />
                      ) : (
                        <InputFieldV2<IJoinFormInput>
                          theme={InputFieldTheme.WHITE}
                          placeholder={'CITY'}
                          name="city"
                          control={control}
                          fullWidth={true}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.inputGroupRow}>
                    <InputFieldV2<IJoinFormInput>
                      theme={InputFieldTheme.WHITE}
                      placeholder={'STREET ADDRESS'}
                      name="street"
                      control={control}
                    />
                    {nationality === Nationality.US ? (
                      <InputFieldV2<IJoinFormInput>
                        theme={InputFieldTheme.WHITE}
                        placeholder={'ADDRESS LINE 2'}
                        name="streetAddressOptional"
                        control={control}
                      />
                    ) : (
                      <InputFieldV2<IJoinFormInput>
                        theme={InputFieldTheme.WHITE}
                        placeholder={'CITY'}
                        name="city"
                        control={control}
                      />
                    )}
                  </div>
                )}
                {isMobile ? (
                  <>
                    {nationality === Nationality.US && (
                      <>
                        <div className={styles.inputGroupRow}>
                          <InputFieldV2<IJoinFormInput>
                            theme={InputFieldTheme.WHITE}
                            placeholder={'CITY'}
                            name="city"
                            control={control}
                            fullWidth={true}
                          />
                        </div>
                        <div className={styles.inputGroupRow}>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <ControlledDropdown
                              placeholderColor="#fff"
                              borderColor="#fff"
                              arrowColor="#fff"
                              isError={!!errors.primState}
                              errorMessage={errors.primState?.message}
                              placeholder="STATE"
                              name="primState"
                              dropdownOptions={stateOptions}
                              control={control}
                              initValue={{
                                label: 'STATE',
                                value: 'STATE',
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {nationality === Nationality.US && (
                      <div className={styles.inputGroupRow}>
                        <InputFieldV2<IJoinFormInput>
                          theme={InputFieldTheme.WHITE}
                          placeholder={'CITY'}
                          name="city"
                          control={control}
                        />
                        <div style={{ display: 'flex', width: '49%' }}>
                          <ControlledDropdown
                            placeholderColor="#fff"
                            borderColor="#fff"
                            arrowColor="#fff"
                            isError={!!errors.primState}
                            errorMessage={errors.primState?.message}
                            placeholder="STATE"
                            name="primState"
                            dropdownOptions={stateOptions}
                            control={control}
                            initValue={{
                              label: 'STATE',
                              value: 'STATE',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className={styles.inputGroupRow}>
                  {nationality !== Nationality.US && (
                    <div style={{ display: 'flex', width: '49%' }}>
                      <ControlledDropdown
                        placeholderColor="#fff"
                        borderColor="#fff"
                        arrowColor="#fff"
                        isError={!!errors.country}
                        errorMessage={errors.country?.message}
                        placeholder="COUNTRY"
                        name="country"
                        dropdownOptions={countries}
                        control={control}
                        initValue={{
                          value: 'country',
                          label: 'COUNTRY',
                        }}
                      />
                    </div>
                  )}
                  <InputFieldV2<IJoinFormInput>
                    theme={InputFieldTheme.WHITE}
                    placeholder={
                      nationality === Nationality.US ? 'ZIP CODE' : 'POST CODE'
                    }
                    name="primZip"
                    control={control}
                    fullWidth={nationality !== Nationality.US ? false : true}
                  />
                </div>
                <div className={styles.inputGroupRow}>
                  <InputFieldV2<IJoinFormInput>
                    theme={InputFieldTheme.WHITE}
                    placeholder={'EMAIL'}
                    name="email"
                    control={control}
                    fullWidth={true}
                  />
                </div>
                <div className={styles.inputGroupRow}>
                  <ControlledPhoneDropDown
                    control={control}
                    name="phone"
                    isError={!!errors.phone}
                    errorMessage={errors.phone?.message}
                    placeholder="PHONE NUMBER"
                    placeholderColor={'#fff'}
                  />
                </div>
                <CustomButton
                  btnStyle={'inlineDarkAlternative'}
                  onClick={handleSubmit(onSubmit)}
                >
                  GET ON THE LIST
                </CustomButton>
              </div>
            </>
          )}
          {isSubmitSuccessful && (
            <div className={styles.successContainer}>
              <h2 className={styles.formTitleSuccessAlt}>Success</h2>
              <p className={styles.formTextSuccessAlt}>
                A member of our team will contact you to provide more
                information
              </p>
            </div>
          )}
        </div>
        {/* <DevTool control={control} /> */}
      </form>
    </div>
  );
};

export default RoyalAscotForm;
