import { FormControlLabel } from "@mui/material";
import { FC } from "react";

import { RadioButtonProps } from "./RadioButton.type";
import { BlackStyledRadio, WhiteStyledRadio } from "./styledComponent";

const BlackRadio: FC<RadioButtonProps> = ({
  label,
  onChange,
  error,
  value,
  onClick,
  ...props
}) => {
  return (
    <div>
      <FormControlLabel
        control={
          <BlackStyledRadio
            error={error.toString()}
            checked={value}
            onClick={onClick}
            sx={{ width: "34px", height: "34px" }}
            {...props}
            onChange={onChange}
          />
        }
        label={label}
        style={{
          color: error ? "red" : "#000",
          fontSize: "16px",
          lineHeight: "20.51px",
          marginLeft: 0,
          display: "flex",
          //gap: '10px',
          fontFamily: "Futura PT Book",
          fontWeight: 400,
        }}
      />
    </div>
  );
};

const WhiteRadio: FC<RadioButtonProps> = ({
  label,
  onChange,
  error,
  value,
  onClick,
  ...props
}) => {
  return (
    <div>
      <FormControlLabel
        control={
          <WhiteStyledRadio
            error={error.toString()}
            checked={value}
            sx={{ width: "34px", height: "34px" }}
            onClick={onClick}
            {...props}
            onChange={onChange}
          />
        }
        label={label}
        style={{
          color: error ? "red" : "#fff",
          fontSize: "16px",
          lineHeight: "20.51px",
          marginLeft: 0,
          display: "flex",
          //gap: '10px',
          fontFamily: "Futura PT Book",
          fontWeight: 400,
        }}
      />
    </div>
  );
};

const RadioButtons = {
  Black: BlackRadio,
  White: WhiteRadio,
};

export default RadioButtons;
