import FooterNew from 'components/Footer/FooterNew';
import Header from 'components/Header/Header';
import HeroSection from 'components/HomePageComponents/HeroSection/HeroSection';
import VideoBlock from 'components/HomePageComponents/VideoBlock/VideoBlock';
import { MAIN_URL, ROUTES } from 'utils/constants';
import styles from './HomePage.module.scss';
import FeaturedExperiences from 'components/HomePageComponents/FeaturedExperience/FeaturedExperience';
import UpcomingEvents from 'components/HomePageComponents/UpcomingEvents/UpcomingEvents';
import PreviousEvents from 'components/HomePageComponents/PreviousEvents/PreviousEvents';
import Gallery from 'components/HomePageComponents/GalleryOfPreviousEvents/Gallery';

const EXPERIENCES_VIDEO_LINK =
  process.env.REACT_APP_EXPERIENCES_LINK ||
  'https://player.vimeo.com/video/930645449';

const HomePage = () => {
  return (
    <div className={styles.mainContainer}>
      <Header />
      <VideoBlock link={EXPERIENCES_VIDEO_LINK} />
      <HeroSection />
      <FeaturedExperiences />
      <UpcomingEvents />
      <PreviousEvents />
      <Gallery />
      <FooterNew
        pageName="Experience Home page"
        pageUri={`${MAIN_URL}${ROUTES.HOME}`}
      />
    </div>
  );
};

export default HomePage;
