import { useSwiper } from 'swiper/react';

import styles from './SwiperSlideControls.module.scss';
import ArrowLeft from 'assets/icons/ArrowLeft';
import ArrowRight from 'assets/icons/ArrowRight';

type SwiperSlideControlsProps = {
  slideIndex: number;
  totalSlides: number;
};

export const getRealSlideIndex = (index: number, totalSlides: number) => {
  if (index > totalSlides - 1) {
    return index - totalSlides + 1;
  }
  return index + 1;
};

export const SwiperSlideControls = ({
  slideIndex,
  totalSlides,
}: SwiperSlideControlsProps) => {
  const swiper = useSwiper();

  return (
    <div className={styles.swiperPagination}>
      <button
        className={styles.slideControlButton}
        onClick={() => swiper.slidePrev()}
      >
        <ArrowLeft />
      </button>
      <p className={styles.slideIndicator}>
        {getRealSlideIndex(slideIndex, totalSlides)} / {totalSlides}
      </p>
      <button
        className={styles.slideControlButton}
        onClick={() => swiper.slideNext()}
      >
        <ArrowRight />
      </button>
    </div>
  );
};
