import { createTheme } from '@mui/material/styles';

const getFooterHeightPx = () => {
  return 10 + (document.getElementById('LuxusFooter')?.offsetHeight ?? 150);
};
const getHeaderHeightPx = () => {
  return 10 + (document.getElementById('LuxusHeader')?.offsetHeight ?? 62);
};

const minPageHeight = (viewHeight: number) => {
  return viewHeight - getFooterHeightPx() - getHeaderHeightPx();
};

export const themeData = {
  getHighlightColor: () => {
    return '#568DFF';
  },
  getDarkBackgroundColor: () => {
    return '#1D1D1D';
  },
  getLightBackgroundColor: () => {
    return '#F7F7F7';
  },
  getLowlightColor: () => {
    return '#D7E4FF';
  },
  getWhiteColor: () => {
    return '#FFFFFF';
  },
  getBlackColor: () => {
    return '#000000';
  },
  getFooterHeight: () => {
    return `${getFooterHeightPx()}px`;
  },
  getHeaderHeight: () => {
    return `${getHeaderHeightPx()}px`;
  },
  getFooterHeightPx: getFooterHeightPx,
  getHeaderHeightPx: getHeaderHeightPx,
  minPageHeight: minPageHeight,
  HighlightColor: '#568DFF',
  DarkBackgroundColor: '#1D1D1D',
  // LightBackgroundColor: "#E5E5E5",
  AboutTextColor: '#1e1e1e',
  LightBackgroundColor: '#F7F7F7',
  SemiLightBackgroundColor: '#EEEEEE',
  LowlightColor: '#D7E4FF',
  AccentColor: '#D9FC77',
  WhiteColor: '#FFFFFF',
  BlackColor: '#000000',
  PrimaryFont: 'Telugu MN',
  DefaultFont: 'Futura PT',
  DefaultFontBook: 'Futura PT Book',
  DefaultFontDemi: 'Futura PT Demi',
  DefaultFontHeavy: 'Futura PT Heavy',
  TextColor: '#151515',
  FinePrintColor: '#808080',
  GreyText: '#4F4F4F',
  GreyLabel: '#1D1D1D',
  ErrorTextColor: '#cd263a',
  SuccessTextColor: '#489b39', //'#59ca47'
  DefaultActionColor: '#000000',
  DisabledBackground: '#E0E0E0',
  DisabledColor: '#00000050',
  BorderColor: '#CBCBCB',
  CancelButtonBackground: '#C9C9C9',
  TransparentColor: '#00000000',
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: themeData.HighlightColor,
    },
    secondary: {
      main: '#555555',
    },
    success: { main: themeData.SuccessTextColor },
    error: { main: themeData.ErrorTextColor },
    text: { primary: themeData.BlackColor },
  },
  typography: {
    fontFamily: 'Futura PT',
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: themeData.BlackColor,
        },
        input: {
          '::placeholder': {
            fontFamily: themeData.DefaultFont,
            fontSize: '20px',
            fontWeight: 400,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: '5px !important',
        },
      },
    },
  },
});

export default themeData;
