import React, { FC } from "react";
import { Box } from "@mui/material";

import Header from "../../components/Header/Header";
import FooterNew from "components/Footer/FooterNew";

const BaseLayout: FC<{
  children: React.ReactNode;
  color?: "white" | "black";
  removeForm?: boolean;
  pageName: string;
  pageUri: string;
  isBlackLogo?: boolean;
}> = ({ children, pageName, pageUri, isBlackLogo = false }) => {
  return (
    <Box sx={{ backgroundColor: "inherit", height: "100%", width: "100%" }}>
      <Box sx={{ backgroundColor: "inherit" }}>
        <Header isBlackLogo={isBlackLogo} />
        {children}
        <FooterNew pageName={pageName} pageUri={pageUri} />
      </Box>
    </Box>
  );
};

export default BaseLayout;
